.button {
  cursor: pointer;
  border: none;
  border-radius: var(--spacing-radius);
  background-color: inherit;
  padding: 5px;
  color: var(--colour-secondary);
  font-family: inherit !important;
}

.button:hover {
  color: var(--colour-action);
}

.button:focus {
  outline: none;
}
