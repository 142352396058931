.faq-container {
  width: 100%;
}

.faq-item {
  border-bottom: 1px solid #ccc;
}

.faq-question {
  cursor: pointer;
  padding: 15px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  flex: 1;
}

.icon.active {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 15px;
  background-color: #fff;
  display: block;
}
