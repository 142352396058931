.error {
  color: var(--colour-error);
}

.accept {
  text-align: center;
}

.accept button {
  margin: var(--spacing-lg);
}
