.actions {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-sm);
}

.actions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
  border: 0;
}

.actions .secondaryButton {
  background-color: var(--colour-action);
}
