@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #767676;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: spinner 0.6s linear infinite;
}

.fullPage {
  position: absolute;
  top: calc(50% - 2.28571429rem / 2);
  right: calc(50% - 2.28571429rem / 2);
}
