/* ImageSlider.css - Responsive Multi-Thumbnail */

.image-slider-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 calc(100% / 8); /* 4 thumbnails per view */
  padding: 5px;
  transition: opacity 0.5s ease-in-out;
}

.slide img {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.slider-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 10px;
}

.slider-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.indicator-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
}

.indicator.active {
  background-color: white;
}

/* Responsive for smaller screens */
@media screen and (max-width: 900px) {
  .slide {
    flex: 0 0 calc(100% / 5); /* Show 2 images per view on tablets */
  }
}

@media screen and (max-width: 600px) {
  .slide {
    flex: 0 0 calc(100% / 3); /* Show 2 images per view on tablets */
  }
}
