.items {
  margin-top: var(--spacing-sm);
}

.items > div {
  padding: var(--spacing-sm) 0;
}

.name {
  margin-left: var(--spacing-sm);
}

.add {
  color: var(--colour-action);
  padding: var(--spacing-sm);
}
