.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding: 20px;
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
}

.reminderList {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.imglogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes blink {
  0% {
    scale: 100%;
    opacity: 1;
  }
  50% {
    scale: 98%;
    opacity: 0.9;
  }
  100% {
    scale: 100%;
    opacity: 1;
  }
}

.blinkingButton {
  animation: blink 1s infinite;
}
