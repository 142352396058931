.section {
  margin-bottom: var(--spacing-sm);
}

.sectionDopeSummary {
  margin-bottom: var(--spacing-sm);
}

@media only screen and (max-width: 500px) {
  .grid {
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
  .gridSummary {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

@media only screen and (min-width: 501px) {
  .grid {
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 10fr));
  }
  .gridSummary {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
}

.openSectionButton {
  font-size: 10px;
  font-family: inherit;
  color: white;
  width: 103%;
  background-color: purple;
  border: 1;
  margin-top: -5px;
  margin-left: -5px;
  text-align: center;
  font-style: italic;
}

.blinkingButton {
  animation: blink 4s infinite;
}

@keyframes blink {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
