.footer {
  padding: 0 auto;
  box-sizing: border-box;
  text-align: center;
  padding: var(--spacing-md);
  width: 100%;
  margin: 0 auto;
  background-color: var(--colour-background);
  filter: invert(100%);
}
