.category {
  text-align: center;
  padding: var(--spacing-md);
  margin: var(--spacing-sm) 0;
  cursor: pointer;
}

.gridContainer {
  display: grid;
  grid-template-columns: 20fr 1fr;
  grid-gap: 10px;
}