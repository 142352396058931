.content {
  flex: 1;

  margin: 0 auto;
  padding: var(--spacing-lg);

  width: 100%;
  box-sizing: border-box;
  /* background-color: var(--colour-background);*/
}

.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
}
