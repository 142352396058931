.container {
  display: flex;
  margin-bottom: var(--spacing-md);
}

.button {
  flex: 1;
  border: 1;
  background-color: transparent;
  padding: var(--spacing-sm) 0;
  cursor: pointer;
  outline: none;
  font-family: inherit !important;
}

.buttonXXXX:hover {
  color: var(--colour-secondary);
}

.selected {
  background-color: var(--colour-selected);
  color: var(--colour-secondary);
}
