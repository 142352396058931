.container {
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
  margin-right: var(--spacing-sm);
}

.button {
  cursor: pointer;
  border: none;
  border-radius: var(--spacing-radius);
  background-color: inherit;
  padding: var(--spacing-md);
  color: var(--colour-secondary);
  font-family: inherit !important;
}

.button:hover {
  background-color: var(--colour-hover) !important;
}

.button:focus {
  outline: none;
}
