.app {
  background-color: var(--colour-background);
  color: var(--colour-primary);
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: var(--font-family);
}

.app::-webkit-scrollbar {
  display: none;
}
.app {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"),
    url("./Fonts/Caveat/Caveat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "IndieFlower";
  src: local("IndieFlower"),
    url("./Fonts/Indie_Flower/IndieFlower-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ShadowsIntoLight";
  src: local("ShadowsIntoLight"),
    url("./Fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "PatrickHand";
  src: local("PatrickHand"),
    url("./Fonts/Patrick_Hand/PatrickHand-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CabinSketch";
  src: local("CabinSketch"),
    url("./Fonts/Cabin_Sketch/CabinSketch-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
    url("./Fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Fondamento";
  src: local("Fondamento"),
    url("./Fonts/Fondamento/Fondamento-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HomemadeApple";
  src: local("HomemadeApple"),
    url("./Fonts/Homemade_Apple/HomemadeApple-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "JosefinSlab";
  src: local("JosefinSlab"),
    url("./Fonts/Josefin_Slab/JosefinSlab-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Kalam";
  src: local("Kalam"), url("./Fonts/Kalam/Kalam-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./Fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "OleoScript";
  src: local("OleoScript"),
    url("./Fonts/Oleo_Script/OleoScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SourceCodePro";
  src: local("SourceCodePro"),
    url("./Fonts/Source_Code_Pro/SourceCodePro-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"),
    url("./Fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
    url("./Fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "VT323";
  src: local("VT323"), url("./Fonts/VT323/VT323-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "XanhMono";
  src: local("XanhMono"),
    url("./Fonts/Xanh_Mono/XanhMono-Regular.ttf") format("truetype");
}
