.App {
  /* Changed from .suduko to match your React component */
  text-align: center;
  margin-top: 20px;
}
.timer {
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;
}

.board-container {
  position: relative;
  display: inline-block;
}

.board {
  border: 2px solid #333;
}

.row {
  display: flex;
}

.cell {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cell.initial {
  background-color: #e0e0e0;
  font-weight: bold;
  cursor: default;
}

.cell.selected {
  background-color: #d0e8ff;
}

.row:nth-child(3n) {
  border-bottom: 2px solid #333;
}

.row .cell:nth-child(3n) {
  border-right: 2px solid #333;
}

.row:last-child {
  border-bottom: none;
}

.row .cell:last-child {
  border-right: none;
}

.buttons {
  margin-top: 20px;
}

buttonSudoku {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.best-times {
  width: 80%;
  margin-top: 60px;

  border: 1;
  border-style: dashed;
}

.best-times h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.best-times ul {
  list-style: none;
  padding: 0;
}

.best-times li {
  font-size: 16px;
  margin: 5px 0;
}

.number-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  z-index: 10;
}

.number-button {
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-button.highlighted {
  background-color: #ffd700;
}

.number-button.clear {
  grid-column: 1 / 4;
  background-color: #ffcccc;
  width: 90%;
}

.validation-popup {
  position: fixed !important;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 2px solid #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 20;
  text-align: center;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.validation-popup.win {
  background: linear-gradient(135deg, #9101a4, #4a0252); /* Gradient for win */
  opacity: 0.9;
  position: relative;
  overflow: hidden;
}

.win-message {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Confetti animation */

@keyframes confetti {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
