/* src/components/ChoreChart/ChoreChart.css */

.chore-chart {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 20px auto;
}

.chore-chart h2 {
  text-align: center;
  color: #27ae60;
  margin-bottom: 20px;
}

.chore-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.input-field,
.select-field {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
  flex: 1 1 150px;
}

.add-button {
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.add-button:hover {
  background-color: #1e8449;
}

.chores-list p {
  text-align: center;
  color: #777777;
}
