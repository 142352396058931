.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.darkMode {
  background-color: black;
}

.darkMode h1 {
  color: white;
}

.ql-editor {
  font-size: inherit;
  line-height: 1.5;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 100vh;
}

.notesList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  grid-gap: 10px;
}

.toggleList {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
}

.noteNew {
  background-color: #67d7cc;

  border-radius: 5px;
}

textarea {
  border: none;
  resize: none;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 10px;
  /*background-color: #67d7cc;*/
  background-color: chocolate;
  max-height: 350px;
  max-width: 100%;
}

.textareamodal {
  border: none;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  /*background-color: #67d7cc;*/
  background-color: chocolate;
  max-height: 65vh;
  max-width: 100%;
}

.textareaaddnew {
  border: none;
  resize: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  /*background-color: #67d7cc;*/
  background-color: chocolate;
  max-height: 100vh;
}

.noteContainer {
  border-radius: 10px;
}

textarea:focus {
  outline: none;
}

.save {
  background-color: #e1e1e1;
  border: none;
  font-size: inherit;
  font-family: inherit;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
}

.save:hover {
  background-color: #ededed;
  cursor: pointer;
}

.note {
  background-color: #fef68a;
  border-radius: 5px;
  padding: 1em;
  overflow-wrap: break-word;
  min-height: 300px;
  text-overflow: ellipsis;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;
}

.noteWidget {
  background-color: #fef68a;
  border-radius: 5px;
  padding: 1em;
  overflow-wrap: break-word;
  padding-bottom: 20px;

  overflow: "scroll";
  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
  border-radius: 10px;
}

.noteModal {
  background-color: #fef68a;
  border-radius: 5px;
  padding: 1em;
  overflow-wrap: break-word;
  min-height: 170px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  white-space: pre-wrap;

  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
  border-radius: 10px;
}

.noteFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noteFooterDate {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-icon {
  cursor: pointer;
}

.search {
  display: flex;
  align-items: center;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 1.5em;
  border-style: solid;
  border-color: lightgrey;
  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
}

.search input {
  border: none;
  padding: 12px;
  font-size: 18px;
  background-color: rgb(233, 233, 233);
  width: 100%;
}

.search input:focus {
  outline: none;
  padding-left: 20px;
}

.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
}
