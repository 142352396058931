.button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  color: var(--colour-action);
  font-family: inherit !important;
}

.button:hover {
  color: var(--colour-hover);
  cursor:"pointer";
}

.button:focus {
  outline: none;
}

.link {
  text-align: left;
}

.success {
  color: var(--colour-success);
}

.success button {
  color: var(--colour-success);
}
