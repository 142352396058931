.sign-in-container {
  text-align: center;
  margin-top: 20px;
  width: calc(50% - 10px);
}

.sign-in-header {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  padding: 20px;
  margin: 10px auto;
  border-radius: 12px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease-in-out;
}

.sign-in-logo {
  width: 24px;
  height: 24px;
  padding-right: 10px;
  margin-right: 10px;
}

/* Google Button */
.sign-in-button.google {
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
}

.sign-in-button.google:hover {
  background-color: #f1f1f1;
}

/* Apple Button */
.sign-in-button.apple {
  background-color: black;
  color: white;
}

.sign-in-button.apple:hover {
  background-color: #333;
}

/* Guest Button */
.sign-in-button.guest {
  background-color: #f5b700;
  color: black;
}

.sign-in-button.email {
  background-color: #28a745;

  color: white;
}

.sign-in-button.guest:hover {
  background-color: #e6a600;
}

/* Logout Button */
.sign-in-button.logout {
  background-color: red;
  color: white;
}

.sign-in-button.logout:hover {
  background-color: darkred;
}

/* Mobile Optimization */
@media screen and (max-width: 600px) {
  .sign-in-button {
    width: 98%;
  }
  .sign-in-container {
    width: 98%;
  }
}
