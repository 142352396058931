.myform {
  width: 100vw;
  min-width: 100px;
  max-width: 950px;
}
.form {
  border: 1px solid var(--colour-background-tertiary);
  min-width: 170px;
  max-width: 985px;
  margin: auto;
  font-family: inherit;
  font-size: 14px;

  padding: var(--spacing-sm);
  border-radius: var(--spacing-radius);
  border-style: solid;

  border-color: lightgrey;
}

.form input {
  border: none;
  margin-right: var(--spacing-sm);
  width: 100%;
  box-sizing: border-box;
}

.form input:focus {
  outline: none;
}

.form .error {
  color: var(--colour-error);
}

.form .actions {
  display: flex;
  margin-top: var(--spacing-md);
}

.form .actions button {
  /* min-width: 100px; */
  flex: 1;
  cursor: pointer;
  border: none;
  border-radius: var(--spacing-radius);
  background-color: inherit;
  padding: var(--spacing-md);
  color: var(--colour-secondary);
}

.form .actions button:disabled {
  cursor: default;
  color: var(--colour-disabled);
}

.form .actions .add {
  background-color: var(--colour-action);
}

.form .actions .clear {
  color: var(--colour-primary);
  margin-right: var(--spacing-sm);
  background-color: var(--colour-background-light-accent);
}

.form .actions .clear:hover:enabled {
  color: var(--colour-action);
}

@keyframes blink {
  0% {
    scale: 100%;
    opacity: 1;
  }
  50% {
    scale: 98%;
    opacity: 0.9;
  }
  100% {
    scale: 100%;
    opacity: 1;
  }
}

.blinkingButton {
  animation: blink 1s infinite;
}
