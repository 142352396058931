/* src/components/Hangman/Hangman.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.hangman-container {
  text-align: center;
  background-color: #f5f5f5;
  color: #333;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.hangman-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #4a90e2;
}

.game-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.category {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #555;
}

.word {
  font-size: 2rem;
  letter-spacing: 5px;
  margin-bottom: 20px;
  min-height: 50px;
}

.letter {
  display: inline-block;
  width: 30px;
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.letter-button {
  background-color: #4a90e2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 12px;
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.letter-button:hover:not(:disabled) {
  background-color: #357ab8;
  transform: translateY(-2px);
}

.letter-button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.status {
  margin-top: 10px;
}

.win {
  color: #27ae60;
  font-size: 1.2rem;
  font-weight: bold;
}

.lose {
  color: #e74c3c;
  font-size: 1.2rem;
  font-weight: bold;
}

.new-game-button {
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.new-game-button:hover {
  background-color: #1e8449;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .hangman-container {
    padding: 15px;
  }

  .hangman-container h2 {
    font-size: 1.5rem;
  }

  .word {
    font-size: 1.5rem;
    letter-spacing: 3px;
  }

  .letter-button {
    padding: 8px 10px;
    margin: 3px;
    font-size: 0.9rem;
  }

  .new-game-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
.letter-button.correct {
  background-color: #27ae60; /* Green for correct */
}

.letter-button.incorrect {
  background-color: #e74c3c; /* Red for incorrect */
}
