.category {
  padding: var(--spacing-md);
  border-radius: var(--spacing-radius);
  margin-bottom: var(--spacing-md);

  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
  border-radius: 10px;
  width: 100%;
  user-select: none;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  padding-top: 5px;
}

.categoryHeader button {
  padding: var(--spacing-sm);
  color: var(--colour-action);
}

.items > * {
  /* margin: var(--spacing-sm) 0;*/
  border-style: dashed !important;
  border: 1;
  border-color: rgba(128, 128, 128, 0.6);
  padding: 10;
}

.items > *:last-child {
  /*margin-bottom: 0;*/
}
