.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flagButton {
  padding: var(--spacing-md);
}

.flagged {
  color: var(--colour-danger);
}

.isFavoriteButton {
  padding: var(--spacing-md);
}

.isFavorite {
  color: gold;
  border: 4;
  border-color: black;
}

.input {
  width: 100%;
  min-height: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: var(--spacing-radius);
}

.actions {
  margin: var(--spacing-sm) 0;
}

.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  
}

.infoSection {
  color: navy
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
}
