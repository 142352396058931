.list {
  display: flex;
  flex-direction: column;
}

.list > * {
  max-width: 960px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
