.formGroup > * {
  display: block;
}

.error {
  color: var(--colour-error);
}

.submit {
  margin-top: var(--spacing-lg);
  width: 200px;
  padding: 10px;
  background-color: #01d28e;
  color: white;
  border-radius: 5px;
}
