.recipe {
  padding: var(--spacing-sm);
  cursor: pointer;
  border-radius: var(--spacing-radius);
  min-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.recipe:hover {
  color: var(--colour-action);
  background-color: var(--colour-background-hover);
}

.ingredients {
  color: var(--colour-muted);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
