.itemContent {
  overflow-x: hidden;
}

input[type="checkbox"] {
  transform: scale(2);
  max-width: 25px;
}

.content {
  display: flex;
  user-select: none;
}

.content input {
  cursor: pointer;
  margin: 0 var(--spacing-md);
  align-self: center;
}

.details {
  width: 100%;

  cursor: pointer;
  border-radius: var(--spacing-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  margin-top: 0px;
}

.details:hover {
  color: var(--colour-action);
}

.details .flag {
  color: var(--colour-danger);
  margin-right: var(--spacing-sm);
}

.details span,
small {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
