.modal {
  max-width: 100%;
  width: 100vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  border: 1px solid lightgrey;
  border-style: solid;
  background: rgb(255, 255, 255);
  border-radius: var(--spacing-radius);
  outline: none;
  padding: 15px;
  padding-top: 100px;
  filter: invert(100%);
}

.modal:focus {
  outline: none;
}
