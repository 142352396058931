/* src/components/Hangman/HangmanFigure.css */

.hangman-figure {
  margin: 0 auto 20px auto;
  display: block;
  width: 100%;
  max-width: 200px;
  height: auto;
}

@media (max-width: 600px) {
  .hangman-figure {
    max-width: 200px;
  }
}
