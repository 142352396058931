/* src/components/ChoreChart/Chore.css */

.chore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdfdfd;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.chore.completed {
  background-color: #e8f5e9;
  text-decoration: line-through;
  color: #777777;
}

.chore-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
}

.chore-name {
  font-weight: bold;
  font-size: 1rem;
}

.chore-assigned,
.chore-frequency {
  font-size: 0.9rem;
  color: #555555;
}

.chore-actions {
  display: flex;
  gap: 10px;
}

.complete-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.complete-button {
  color: #27ae60;
}

.complete-button:hover {
  color: #1e8449;
}

.delete-button {
  color: #e74c3c;
}

.delete-button:hover {
  color: #c0392b;
}

@media (max-width: 600px) {
  .chore-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .chore-actions {
    flex-direction: column;
  }

  .complete-button,
  .delete-button {
    font-size: 1rem;
  }
}
