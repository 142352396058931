.gridHome {
    display:grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(
		auto-fill,
		minmax(200px, 1fr)
	);
    
  }
  .grid {
    display:grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(
		auto-fill,
		minmax(120px, 1fr)
	);
    
  }