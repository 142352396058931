.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editButton {
  padding: var(--spacing-sm);
  color: var(--colour-action);
}

.recipeUrl {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.actions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
}
