.button {
  border: none;
  background-color: var(--background-color);
  border-radius: var(--spacing-radius);
  outline: none;
  font-family: inherit !important;
}

.button:enabled {
  cursor: pointer;
}

.button:hover:enabled {
  background-color: var(--colour-background-hover);
}

.button:disabled {
  color: var(--colour-disabled);
}

.primary {
  background-color: var(--colour-action);
  color: var(--colour-secondary);
}

.secondary {
  background-color: var(--colour-background-tertiary);
  color: var(--colour-secondary);
}
