.category {
  padding: var(--spacing-md);
  border-radius: var(--spacing-radius);
  margin-bottom: var(--spacing-md);
  border-style: hidden;
  border-color: transparent;
  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
  user-select: none;
  border-radius: 10px;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  font-size: 1em;
  padding-top: 5px;
}

.categoryHeader button {
  padding: var(--spacing-sm);
  color: var(--colour-action);
}

.items > * {
  margin: var(--spacing-sm) 0;
}

.items > *:last-child {
  margin-bottom: 0;
}

/* Default background color */
.sortable-item {
  --background-color: #f0f0f0; /* Light background */
}

.sortable-item.inverted {
  --background-color: #333; /* Dark background */
}

/* Apply background color dynamically */
.sortable-item {
  background-color: var(--background-color);
}
