.header {
  /* background-color: var(--colour-background);*/

  color: white;
  position: fixed;
  filter: none !important;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 50px;
}

.headerTop {
  /* background-color: var(--colour-background);*/

  color: white;
  filter: none !important;
  position: fixed;
  top: 40;
  z-index: 2;
  width: 100%;
  height: 60px;
  opacity: 1;
}

.footerButtonUnhide {
  color: rgb(220, 29, 29);
  position: fixed;
  filter: none !important;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 25px;
  font-size: 18;
}

.header nav {
  /* padding: var(--spacing-lg);*/
  box-sizing: border-box;
  max-width: var(--page-width);
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.brand {
  margin: 0;
  display: inline-block;
}

.brand + small {
  margin-left: 5;
}

.links {
  display: flex;
  align-items: flex-end;
}

.links a {
  padding: 0 var(--spacing-sm);
}

.links a:last-of-type {
  margin-right: var(--spacing-lg);
}

.active {
  font-weight: bold;
}

.logout {
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 0;
  color: var(--colour-action);
}

.logout:hover {
  color: var(--colour-hover);
}

.modalActions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.modalActions .secondaryButton {
  background-color: var(--colour-background-tertiary);
}

.modalActions button {
  padding: var(--spacing-md);
  background-color: var(--colour-action);
  color: var(--colour-secondary);
  border-radius: var(--spacing-radius);
  margin-left: var(--spacing-sm);
}

.timepicker .react-time-picker__inputGroup {
  font-size: 20px;
  padding: 0px 10px;
}

.blinkingButton {
  animation: blink 4s infinite;
}

@keyframes blink {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
